<template>
	<div class="info-detail-page" style="padding-top: 15px;">
		<van-row class="desc">
			<van-col span="24" v-html="intro"></van-col>
		</van-row>

		<van-row style="padding-top: 30px;padding-bottom:60px;text-align:center;">
			<van-col span="4"></van-col>
			<van-col span="6" @click="useful"><van-icon name="good-job-o" size="30" color="#FE6E1E"/><br /><br />有帮助({{intro_useful}})</van-col>
			<van-col span="4"></van-col>
			<van-col span="6" @click="useless"><van-icon name="good-job-o" size="30" color="#FE6E1E" style="transform: rotateX(180deg);" /><br /><br />没有帮助({{intro_useless}})</van-col>
			<van-col span="4"></van-col>
		</van-row>		
	</div>
</template>

<script>
	export default {
		name: 'fund_intro',
		data() {
			return {
				id: 0,
				intro: "",
				intro_useful: "",
				intro_useless: "",

				disabled_useful: false,
				disabled_useless: false,

			}
		},
		
		mounted() {
			this.id = this.$route.query.id
		
			this.loadData()
		},
		activated() {
			//	如果第二次进入修改的数据不同，则重新获取数据
			if (this.$route.query.id != this.id) {
				this.id = this.$route.query.id
		
				this.loadData()
			}
		},
		methods: {
			loadData() {
				this.$axios({
					method: 'post',
					url: 'fund_fund/intro', 
					data: {
						id: this.id
					}
				}).then ((res) => {
					if (res.success) {						
						this.intro = res.data.intro
						this.intro_useful = res.data.intro_useful		
						this.intro_useless = res.data.intro_useless				
					}
				})
			},

			useful() {
				if(this.disabled_useful){
					this.$toast.fail('不可重复点击')
				}else{
					this.$axios({
						method: 'post',
						url: 'fund_fund/useful', 
						data: {
							id: this.id
						}
					}).then ((res) => {
						if (res.success) {
							let num = parseInt(this.intro_useful)
							num ++
							this.intro_useful = num

							this.disabled_useful = true
						}
					})
				}				
			},

			useless() {
				if(this.disabled_useless){
					this.$toast.fail('不可重复点击')
				}else{
					this.$axios({
						method: 'post',
						url: 'fund_fund/useless', 
						data: {
							id: this.id
						}
					}).then ((res) => {
						if (res.success) {
							let num = parseInt(this.intro_useless)
							num ++
							this.intro_useless = num

							this.disabled_useless = true
						}
					})
				}
			},
		},
	}
</script>